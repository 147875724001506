import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../../components/layout/layout'
import Header from '../../../components/header/header'
import Nav from '../../../components/nav/nav'
import Footer from '../../../components/footer/footer'
import styles from './ceramics-and-mixed-media.module.css'

import earlyWorksThumb from '../../../assets/images/albums/ceramics-and-mixed-media/ceramics-early-works-thumbnail.jpg'
import recentWorksThumb from '../../../assets/images/albums/ceramics-and-mixed-media/ceramics-recent-works-thumbnail.jpg'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <article className={styles.wrapper}>
        <div className={styles.galleryItem}>
          <Link to="/albums/ceramics-and-mixed-media/recent-works">
            <img
              src={recentWorksThumb}
              alt="A thumbnail for the recent works ceramcis gallery"
              className={styles.galleryThumbnail}
            />
          </Link>
          <h2 className={styles.caption}>Recent Works</h2>
        </div>

        <div className={styles.galleryItem}>
          <Link to="/albums/ceramics-and-mixed-media/early-works">
            <img
              src={earlyWorksThumb}
              alt="A thumbnail for the early works ceramics and mixed media gallery"
              className={styles.galleryThumbnail}
            />
          </Link>
          <h2 className={styles.caption}>Early Works</h2>
        </div>
      </article>
    </main>
    <Footer />
  </Layout>
)
